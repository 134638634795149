import { useState } from 'react'

export const Form = () => {
  var [message, setMessage] = useState('SEND MESSAGE')

  var massage_wait = 'Please wait...'
  var message_success = 'Thank you! Your submission has been received!'

  function handleSubmit(e) {
    e.preventDefault()
    message = setMessage(massage_wait)
    setTimeout(() => {
      message = setMessage(message_success)
      e.target.reset()
    }, 5000)
  }

  return (
    <div id="form" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Have a question?</h2>
          <p></p>
        </div>
        <div className="row">
          <form
            method="get"
            className="contact-modal2_form contact_form form_max-width col-xs-12 col-md-6"
            onSubmit={handleSubmit}
          >
            <div className="form-field-2col">
              <input
                className="form-input w-input"
                maxLength="256"
                name="Contact-2-First-Name-3"
                placeholder="FIRST NAME"
                type="text"
                id="Contact-2-First-Name-3"
                required
              />
              <input
                className="form-input w-input"
                maxLength="256"
                name="Contact-2-Last-Name-2"
                placeholder="LAST NAME"
                type="text"
                id="Contact-2-Last-Name-2"
                required
              />
            </div>
            <div className="form-field-2col is-mobile-1col">
              <input
                className="form-input w-input"
                maxLength="256"
                name="Contact-2-Email-2"
                placeholder="PHONE"
                type="tel"
                id="Contact-2-Email-2"
                required
              />
              <input
                className="form-input w-input"
                maxLength="256"
                name="Contact-2-Phone-2"
                placeholder="EMAIL"
                type="email"
                id="Contact-2-Phone-2"
                required
              />
            </div>

            <div className="w-layout-grid form-radio-2col">
              <label
                id="w-node-_95d5b5af-1126-643a-78db-bce88e8a8281-536b4462"
                className="form-radio w-radio"
              >
                <input
                  id="software_inquiry"
                  type="radio"
                  name="inquiry_type"
                  style={{
                    opacity: 0,
                    position: 'absolute',
                    maxzIndex: -1,
                  }}
                  value="Software_Development"
                />

                <div className="w-form-formradioinput w-form-formradioinput--inputType-custom form-radio-icon w-radio-input"></div>

                <span
                  htmlFor="software_inquiry"
                  className="form-radio-label w-form-label"
                >
                  Software Development
                </span>
              </label>

              <label
                id="w-node-_95d5b5af-1126-643a-78db-bce88e8a8285-536b4462"
                className="form-radio w-radio"
              >
                <input
                  id="telecom_inquiry"
                  type="radio"
                  name="inquiry_type"
                  style={{
                    opacity: 0,
                    position: 'absolute',
                    maxzIndex: -1,
                  }}
                  value="Telecommunication Services"
                />

                <div className="w-form-formradioinput w-form-formradioinput--inputType-custom form-radio-icon w-radio-input"></div>

                <span
                  htmlFor="telecom_inquiry"
                  className="form-radio-label w-form-label"
                >
                  Telecommunication Services
                </span>
              </label>
            </div>

            <textarea
              id="message"
              name="message"
              maxLength="5000"
              placeholder="Type your message..."
              required
              className="form-input is-text-area w-input"
            ></textarea>

            <input
              type="submit"
              className="button-2 w-button"
              value={message}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
