export const Footer = (props) => {
	return (
		<div>
			<div id="footer">
				<div className="container text-center">
					<p>
						&copy; 2024 Atlantic Best Services Inc.
					</p>
				</div>
			</div>
		</div>
	);
};
