export const Industries = (props) => {
    return (
      <div id='industries' className='text-center'>
        <div className='container'>
          <div className='section-title'>
            <h2>Industries We Serve</h2>
            <p></p>
          </div>
          
          <div className='row'>
              {props.data
              ? props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className='col-xs-6 col-md-4'>
                    {' '}
                    <i className={d.icon}></i>
                    <div className='service-desc'>
                      <h3>{d.name}</h3>
                    </div>
                  </div>
                ))
              : 'loading'}
            </div>
          
        </div>
      </div>
    )
  }
  